import App from "../app";
import ProductListPage from "../../../vendor/sepia/shop/src/UI/Boutique/pages/Product/List";
require('publicPath');
var app = new App();
var $ = require('jquery');
var Lazyload = require('lazyload');

require('bootstrap.transition');
require('bootstrap.carousel');

//$('#home-carousel').carousel();
var $images = $('.product img').get();
var lazyload = new Lazyload($images);

ProductListPage('homeList');

var paddingTop = $('.nav-terms').height();
$('.left-column').css('padding-top', paddingTop + 17);
